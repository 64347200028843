import React, { useContext, useEffect, useState } from "react";
import { navigate } from "gatsby";
import { useForm } from "react-hook-form";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import PageWrapper from "../components/PageWrapper";
import { AuthContext } from "../context/AuthContext";
import { validation } from "../utils/validation";
import notify from "../utils/notify";
import { magic } from "../utils/magic";

const Auth = () => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const { register, errors, handleSubmit, reset } = useForm();
  const { auth } = useContext(AuthContext);

  useEffect(() => {
    const getAuth = async () => {
      if (auth) {
        navigate("/dashboard-main");
      } else {
        const isUserLoggedIn = await magic.user.isLoggedIn();
        if (isUserLoggedIn) {
          const user = await magic.user.getInfo();
          if (user && user?.publicAddress) {
            localStorage.setItem("account_id", user.publicAddress);
            navigate("/dashboard-main");
          }
        }
        setLoading(false);
      }
    };
    getAuth();
    return () => setLoading(true);
  }, []);

  const onSubmit = async (data) => {
    try {
      setSubmitting(true);
      setLoading(true);
      const { email } = data;
      reset();
      const result = await magic.auth.loginWithEmailOTP({ email });
      if (result) {
        const user = await magic.user.getInfo();
        if (user && user?.publicAddress) {
          localStorage.setItem("account_id", user.publicAddress);
          navigate("/dashboard-main");
        }
      }
    } catch (error) {
      console.log(error);
      notify(error.message);
      setSubmitting(false);
      setLoading(false);
    }
  };

  return (
    <>
      <PageWrapper headerConfig={auth ? { button: "profile" } : null}>
        <div className="bg-default-2 pt-22 pt-lg-25 pb-13 pb-xxl-32">
          <div className="container">
            <div className="row justify-content-center pt-12">
              <div className="col-12 col-xxl-6 col-lg-6 col-md-6">
                {loading ? (
                  <Skeleton
                    height={44}
                    containerClassName="text-center w-100"
                    className="w-70"
                  />
                ) : (
                  <h3 className="card-title font-size-8 font-weight-bold text-center">
                    Join now
                  </h3>
                )}
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-xxl-6 col-lg-6 col-md-6">
                {loading ? (
                  <Skeleton
                    height={44}
                    containerClassName="text-center w-100"
                    className="w-70"
                  />
                ) : (
                  <p className="font-size-4 text-center pt-0">
                    Sign up with your email to get started.
                  </p>
                )}
              </div>
            </div>

            <div className="row justify-content-center pt-6">
              <div className="col-12 col-xxl-6 col-lg-6 col-md-6">
                <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                  {loading ? (
                    <div className="form-group">
                      <Skeleton
                        height={44}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label
                        htmlFor="email"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Email*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Email address"
                        ref={register(validation.email)}
                      />
                      <span className="text-danger small">
                        {errors?.email?.message || ""}
                      </span>
                    </div>
                  )}

                  <div className="form-group mb-8">
                    {loading ? (
                      <Skeleton
                        height={44}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary btn-medium w-100 rounded-5 text-uppercase focus-reset"
                        disabled={submitting || loading ? true : false}
                      >
                        {submitting ? "Submitting..." : "Submit"}
                      </button>
                    )}
                  </div>

                  <div className="row align-items-center">
                    <div className="col-12">
                      <div className="form-group mb-8">
                        {errors &&
                          Object.values(errors).map((field, index) => (
                            <p
                              key={index}
                              className="text-danger small m-0 p-0"
                            >
                              {field.message}
                            </p>
                          ))}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default Auth;
